export const MODULE_ID = 'meter';

export const BLUETOOTH_PIN_LENGTH = 6;

export const DOWNLOAD_DATA_MODAL = `${MODULE_ID}/downloadDataModal`;

export const BLUETOOTH_PIN_FORM = `${MODULE_ID}/bluetoothPinForm`;

export const CONNECTOR_TYPES = {
  MDA: 'MDA',
  SCC: 'SCC',
} as const;

export const DEVICE_DATA_TYPES = {
  BGM: 'BGM',
  CGM: 'CGM',
} as const;

export const DOWNLOADER_STATUSES = {
  INITIALIZED              : 'Initialized',
  READY                    : 'Ready',
  NO_CONNECTION            : 'NoConnection',
  TIMEOUT                  : 'Timeout',
  UPDATE_ERROR             : 'UpdateError',
  UPDATE_ENDPOINT_NOT_FOUND: 'UpdateEndPointNotFound',
} as const;

export const DRIVER_STATUSES = {
  NOT_STARTED: 'NotStarted',
  STARTED    : 'Started',
  IN_PROGRESS: 'InProgress',
  COMPLETED  : 'Completed',
  ERROR      : 'CompletedWithError',
} as const;

export const CONNECTION_STATUSES = {
  INITIALIZED: 'Initialized',
  IN_PROGRESS: 'InProgress',
  SUCCESS    : 'Success',
  CANCELED   : 'Canceled',
  TIMEOUT    : 'Timeout',
  ERROR      : 'Error',
} as const;

export const WARNING_TYPES = {
  NEW_DEVICE_CONNECTED  : 'NewDeviceConnected',
  UNAVAILABLE_TIME      : 'UnavailableTime',
  MISMATCH_TIME         : 'MismatchTime',
  MISMATCH_TARGET_RANGES: 'MismatchTargetRanges',
} as const;

export const WARNINGS_ORDER = [
  WARNING_TYPES.NEW_DEVICE_CONNECTED,
  WARNING_TYPES.UNAVAILABLE_TIME,
  WARNING_TYPES.MISMATCH_TIME,
  WARNING_TYPES.MISMATCH_TARGET_RANGES,
];


export const CHANNEL_COMPONENTS = {
  BlueCable : 'ConnectBlueCable',
  Bluetooth : 'ConnectBluetooth',
  BuiltInUsb: 'ConnectUsb',
  MicroUsb  : 'ConnectUsb',
  UsbC      : 'ConnectUsb',
};

// ---------------------------------------------------------------------------------------------------------------------

// TODO: Remove
export const MDA_INIT_SUBCOMPONENTS = [
  // 'ConnectionFailed',
  // 'NoLicense',
];

export const DOWNLOADER_APP_NAME = 'Results Downloader App';

export const DOWNLOADER_TRAY_APP_NAME = 'GlucoConnector app';

// ---------------------------------------------------------------------------------------------------------------------


export const SINOVO_LANGUAGES = {
  fr: 'FR',
  nl: 'NL',
};
