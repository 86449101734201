import React, { FC, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useAction } from 'hooks';
import * as actions from '../../actions';
import { DOWNLOADER_STATUSES } from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from '../../components/DownloadDataModal/DownloadDataModal.pcss';


interface Props {
  onInitialized : () => void,
  onSetComponent: (componentName: string) => void,
}


const Initializing: FC<Props> = ({ onInitialized, onSetComponent }) => {
  const downloader: MDA = useSelector(selectors.downloader);
  const status: DownloaderStatus = useSelector(selectors.status);
  const isInProgress: boolean = useSelector(selectors.isCheckDownloaderInProgress);
  const prevIsInProgressRef = useRef<boolean>(isInProgress);
  const checkStatus = useAction(actions.checkStatus);
  const checkBlueCableDriver = useAction(actions.checkBlueCableDriver);

  useEffect(() => {
    checkStatus();
    checkBlueCableDriver();
  }, []);

  useEffect(() => {
    if (prevIsInProgressRef.current !== isInProgress) {
      if (!isInProgress) {
        if (status === DOWNLOADER_STATUSES.READY) {
          onInitialized();
          return;
        }
        if (downloader.mdaVersion) {
          onSetComponent('NoConnection');
        } else {
          onSetComponent('InstallDownloader');
        }
      }
      prevIsInProgressRef.current = isInProgress;
    }
  }, [isInProgress]);

  return (
    <div className={styles.modal__content}>
      <h3 className="modal__subheader "><FormattedMessage {...messages.initializing.header} /></h3>
      <div className={styles.illustration}>
        <img src="/assets/svg/loader.svg" className="rotatingLoader" alt="" />
      </div>
      <p className="modal__info text--center mb-5"><FormattedMessage {...messages.initializing.waiting} /></p>
    </div>
  );
};

export default Initializing;
