import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Modal from 'components/Modal';
import App from 'modules/App';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import messages from '../../messages';
import Initializing from './Initializing';
import ChooseDatabase from './ChooseDatabase';
import ImportPatientDatabase from './ImportPatientDatabase';
import ImportErrorsList from './ImportErrorsList';


class ImportPatientDatabaseModal extends React.PureComponent {

  static propTypes = {
    // Explicit props
    clinicMembership             : shapes.clinicMembership,
    // Implicit props
    importPatientDatabaseProgress: PropTypes.number,
    isInProgress                 : PropTypes.bool,
    isWorking                    : PropTypes.bool,
    openModalId                  : PropTypes.string,
    // Implicit actions
    onCancelImport               : PropTypes.func,
    onEndImport                  : PropTypes.func,
    onCloseModal                 : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.components = {
      Initializing,
      ChooseDatabase,
      ImportPatientDatabase,
      ImportErrorsList,
    };

    this.defaultComponent = 'Initializing';

    this.state = {
      component: this.defaultComponent,
      database : 'GlucoControDesktop',
      isClosing: false,
    };
  }


  componentDidUpdate(prevProps) {
    const { isWorking } = this.props;

    if (prevProps.isWorking !== isWorking) {
      if (isWorking) {
        window.addEventListener('beforeunload', this.onUnload);
      } else {
        this.onModalClose();
        window.removeEventListener('beforeunload', this.onUnload);
      }
    }
  }


  onUnload(evt) {
    evt.preventDefault();
    evt.returnValue = '';
  }


  onSetComponent(componentName) {
    this.setState({ component: componentName });
  }


  onSetDatabase(database) {
    this.setState({ database });
  }


  onClose() {
    const { importPatientDatabaseProgress, isWorking } = this.props;
    if (isWorking && importPatientDatabaseProgress !== 100) {
      this.onClosing();
      return;
    }
    this.onModalClose();
  }


  onClosing() {
    this.setState({ isClosing: true });
    this.props.onCancelImport();
  }


  async onModalClose() {
    await this.props.onCloseModal();
    this.props.onEndImport();
    this.setState({ component: this.defaultComponent, isClosing: false });
  }


  render() {
    const { component } = this.state;
    let headerMessage = messages.headers.importPatientDatabase;
    let styleModifier = 'md';
    const Component = get(this.components, component, null);

    if (component === 'ImportErrorsList') {
      headerMessage = messages.headers.importPatientDatabaseErrors;
      styleModifier = 'lg';
    }

    return (
      <Modal
        modalId={constants.IMPORT_PATIENT_DATABASE_MODAL}
        openModalId={this.props.openModalId}
        headerMessage={headerMessage}
        styleModifier={styleModifier}
        isInProgress={this.state.isClosing}
        onClose={component === 'Initializing' ? () => this.onClose() : null}
      >
        <Component
          database={this.state.database}
          clinicMembership={this.props.clinicMembership}
          importPatientDatabaseProgress={this.props.importPatientDatabaseProgress}
          isInProgress={this.props.isInProgress}
          isWorking={this.props.isWorking}
          isClosing={this.state.isClosing}
          openModalId={this.props.openModalId}
          onSetComponent={(componentName) => this.onSetComponent(componentName)}
          onSetDatabase={(database) => this.onSetDatabase(database)}
          onCancel={() => this.onClose()}
        />
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  importPatientDatabaseProgress: selectors.importPatientDatabaseProgress(state),
  isInProgress                 : selectors.isImportPatientDatabaseInProgress(state),
  isWorking                    : selectors.isImportPatientDatabaseWorking(state),
  openModalId                  : App.selectors.modal(state),
});


const mapDispatchToProps = (dispatch) => ({
  onCancelImport: () => dispatch(actions.importPatientDatabaseCancel()),
  onEndImport   : () => dispatch(actions.importPatientDatabaseEnd()),
  onCloseModal  : () => dispatch(App.actions.closeModal()),
});


const ConnectedImportPatientDatabaseModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImportPatientDatabaseModal);


export default ConnectedImportPatientDatabaseModal;
