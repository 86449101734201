import React, { FC, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useAction } from 'hooks';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from '../../components/DownloadDataModal/DownloadDataModal.pcss';


interface Props {
  onInitialized : () => void,
  onSetComponent: (componentName: string) => void,
}


const useInProgressChange = ({ onInitialized, onSetComponent }: Props) => {
  const status: DownloaderStatus = useSelector(selectors.status);
  const scc: SCC = useSelector(selectors.scc);
  const isInProgress = useSelector(selectors.isCheckSccInProgress);
  const prevIsInProgress = useRef<boolean>(isInProgress);

  useEffect(() => {
    if (isInProgress !== prevIsInProgress.current) {
      if (!isInProgress) {
        if (status === constants.DOWNLOADER_STATUSES.READY) {
          onInitialized();
        } else if (scc.machineId) {
          onSetComponent('NoConnection');
        } else {
          onSetComponent('InstallDownloader');
        }
      }
      prevIsInProgress.current = isInProgress;
    }
  }, [isInProgress]);
};


const Initializing: FC<Props> = (props) => {
  const checkStatus = useAction(actions.checkSccStatus);

  useEffect(() => {
    checkStatus();
  }, []);

  useInProgressChange(props);

  return (
    <div className={styles.modal__content}>
      <h3 className="modal__subheader "><FormattedMessage {...messages.initializing.header} /></h3>
      { /* <p className="modal__info"><FormattedMessage {...messages.downloading.info} /></p> */ }
      <div className={styles.illustration}>
        <img src="/assets/svg/loader.svg" className="rotatingLoader" alt="" />
      </div>
      <p className="modal__info text--center mb-5"><FormattedMessage {...messages.initializing.waiting} /></p>
    </div>
  );
};


export default Initializing;
